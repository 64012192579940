import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Container from "../common/Container";
import Divider from "../common/Divider";
import Grid from "../common/Grid";
import PartnerCloud from "~components/features/PartnerCloud";
import SocialIcons from "~components/features/SocialIcons";
import FormSimple from "../forms/FormSimple";
import H3 from "~components/typography/H3";
import NumberedSection from "~components/typography/NumberedSection";
import P from "~components/typography/P";

function Contact({ form, number, noPadding, headline, className }) {
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					contact {
						street
						plz
						phone
						mail
						city
					}
				}
			}
		}
	`);

	let Tag = headline;
	if (headline) {
		Tag = headline;
	}

	return (
		<Container id="kontakt" noPadding={noPadding} className={`${className ? className : ""}`}>
			<Grid cols={2}>
				<div>
					<Divider className="mb-4" />
					<NumberedSection text="Immer erreichbar" number={number} className="mb-5" />
					<Tag className="mb-4 font-display text-3xl leading-relaxed sm:text-4xl md:text-5xl">
						Entdecke Deinen Weg zur Digitalisierung und kontaktiere uns{" "}
					</Tag>
				</div>
			</Grid>

			<Grid cols={2}>
				<div>
					<H3 className="mb-3">Unsere Kontaktdaten</H3>
					<P className="mb-5">
						Du bist überzeugt? Sende uns eine unverbindliche Nachricht über das Formular oder buche direkt eine
						Dienstleistung. Wir antworten in der Regel innerhalb von 48 Stunden auf Deine Anfrage.
					</P>
					<div className="mb-10 space-y-4">
						<div className="grid gap-2">
							<a href={`tel:${data.site.siteMetadata.contact.phone.replace(/\s/g, "")}`} className="inline-block py-2">
								<span className="font-bold">T:</span> {data.site.siteMetadata.contact.phone}
							</a>

							<a href={`mailto:${data.site.siteMetadata.contact.mail}`} className="inline-block py-2">
								<span className="font-bold">E:</span> {data.site.siteMetadata.contact.mail}
							</a>
						</div>
						<SocialIcons />
					</div>
					<H3 display className="mb-5 font-display">
						Dienstleitungs-Partner
					</H3>
					<P>
						Der richtige Partner ist essenziell für Deinen Erfolg. Deswegen haben wir eine Reihe an wertvollen Partner
						gewonnen, die gemeinsam mit uns Deine Projekte voranbringen. Lautenschlager Marketing & Entwicklung dient
						als Bindeglied für digitale Kompetenz. Dabei geben wir gerne bestimmte Fachbereiche an unsere Partner ab.
						Die Hauptentwicklung und Projektleitung übernehmen aber weiterhin wir.
					</P>
					<PartnerCloud />
				</div>
				<div>
					<H3 className="mb-3">Kontaktformular</H3>
					<div className="bg-pattern p-3">{form}</div>
				</div>
			</Grid>
		</Container>
	);
}

export default Contact;

Contact.propTypes = {
	form: PropTypes.node,
	number: PropTypes.string.isRequired,
	noPadding: PropTypes.bool,
	headline: PropTypes.string,
	className: PropTypes.string,
};

Contact.defaultProps = {
	form: <FormSimple />,
	noPadding: false,
	headline: "h2",
	className: null,
};
