import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Breadcrumb from "~components/layouts/Breadcrumb";
import Container from "~components/common/Container";
import Divider from "~components/common/Divider";
import Grid from "~components/common/Grid";
import Layout from "~components/layouts/Layout";
import Contact from "~components/sections/Contact";
import H2 from "~components/typography/H2";
import NumberedSection from "~components/typography/NumberedSection";
import P from "~components/typography/P";

export default function Page({ data, location }) {
	return (
		<Layout
			location={location}
			title="Beauftragen | Kontaktformular | E-Mail | Telefon"
			desc="Du bist überzeugt? Sende uns eine unverbindliche Nachricht. Wir antworten in der Regel innerhalb von 48 Stunden auf Deine Anfrage."
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Breadcrumb pages={[{ name: "Beauftragen", to: "/beauftragen/" }]} />
			<Contact number="01" className="py-8" noPadding headline="h1" />
			<Container>
				<Grid cols={2} className="mb-16" itemsCenter>
					<div>
						<Divider className="mb-4" />
						<NumberedSection text="Das Bindeglied" number="02" className="mb-5" />
						<H2 display className="mb-5 font-display">
							Ansprechpartner
						</H2>
						<P>
							Der richtige Ansprechpartner ist entscheidend für die erfolgreiche Projektentwicklung. Deswegen hast Du
							bei uns nur einen. So sparst Du Dir die Kommunikation mit unterschiedlichen Personen und hast einen festen
							Ansprechpartner für Deine Projekte. Egal ob es um die Planung, Entwicklung oder Abrechnung geht: Andreas
							Lautenschlager steht Dir immer zur Seite.
						</P>
					</div>
					<figure>
						<StaticImage
							src="../img/pages/index/lautenschlager-marketing-entwicklung_header.png"
							alt="Andreas Lautenschlager"
							layout="fullWidth"
						/>
						<figcaption className="text-center">
							<span className="font-bold">Andreas Lautenschlager</span>
							<br />
							Entwicklung, Consulting und Organisation
						</figcaption>
					</figure>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
				contact {
					street
					plz
					phone
					mail
					city
				}
			}
		}
		seoImage: file(relativePath: { eq: "pages/beauftragen/beauftragen_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}
	}
`;
