import { StaticImage } from "gatsby-plugin-image";
import React from "react";

function PartnerCloud() {
	return (
		<div className="bg-pattern grid grid-cols-1 gap-3 p-2 md:grid-cols-2">
			<div className="flex flex-col items-center justify-center bg-white p-3 text-center">
				<a href="https://www.seo-premium-agentur.de/" target="_blank" rel="noreferrer">
					<span className="sr-only">Zum Partner Seo Agentur Stuttgart</span>
					<StaticImage
						src="../../img/partner/seo-premium-agentur.jpg"
						alt="Lautenschlager Marketing & Entwicklung | Partner Seo Stuttgart"
						title="Lautenschlager Marketing & Entwicklung | Partner Seo Agentur Stuttgart"
						style={{ maxWidth: 150 }}
						aspectRatio={false}
						layout="constrained"
						placeholder="blurred"
					/>
				</a>
			</div>
			{/* <div className="flex items-center justify-center px-8 py-8 text-center bg-white">
        <a href="https://mh-dsgn.de/" target="_blank" rel="dofollow noreferrer">
          <StaticImage
            src="../../img/partner/mh-dsgn.svg"
            alt="Lautenschlager Marketing & Entwicklung | Partner MH | DSGN"
            title="Lautenschlager Marketing & Entwicklung | Partner Partner MH | DSGN"
          />
        </a>
      </div> */}
		</div>
	);
}

export default PartnerCloud;
